import { string, number, oneOfType, object, any } from 'prop-types';

const schema = {
  id: '',
  username: '',
  fullname: '',
  email: '',
  activated: '',
  banned: '0',
  user_banned_until: '',
  ban_reason: null,
  last_login: '',
  created: '',
  modified: '',
  avatar: '',
  about: '',
  location: '',
  website: '',
  occupation: '',
  gender: '',
  birthday: '',
  phone: '',
  address: '',
  hide_email: '0',
  user_attr: '',
  user_priv: '0',
  tutorial: '0',
  user_newsletter: '',
  country: '',
  exchange: '',
  facebook_id: null,
  apple_id: null,
  google_id: null,
  email_subscribe: '',
  user_phone_verification: '',
  facebook_hide: '',
  language: '',
  user_preferences: {},
  user_trading: '',
  user_badge_top: '',
  followed: 0,
  ispro: 0,
  pro_active_since: '',
  pro_expire_at: '',
  tradingpro: 0,
  official: 0,
  trending: 0,
  ir_symbol: '',
  isblocked: 0,
  alert: 0,
  onboarding: 0,
  onboarding_done: 0,
  discover: 0,
  ftcode: '',
  ftend: '',
  realtrading_access: 0,
  top_badge: 0,
  is_term_agreed: 0,
  onesignal_hash: '',
  password: 0,
  support: {
    id: '',
  },
};

const propTypes = {
  id: string,
  username: string,
  fullname: string,
  email: string,
  activated: string,
  banned: string,
  user_banned_until: string,
  ban_reason: string,
  last_login: string,
  created: string,
  modified: string,
  avatar: string,
  about: string,
  location: string,
  website: string,
  occupation: string,
  gender: string,
  birthday: string,
  phone: string,
  address: string,
  user_attr: string,
  user_newsletter: string,
  country: string,
  exchange: string,
  hide_email: string,
  user_priv: string,
  tutorial: string,
  facebook_id: string,
  apple_id: string,
  google_id: string,
  email_subscribe: string,
  user_phone_verification: string,
  facebook_hide: string,
  language: string,
  user_preferences: oneOfType([object, any]),
  user_trading: string,
  user_badge_top: string,
  followed: number,
  ispro: number,
  pro_active_since: string,
  pro_expire_at: string,
  tradingpro: number,
  official: number,
  trending: number,
  ir_symbol: string,
  isblocked: number,
  alert: number,
  onboarding: number,
  onboarding_done: number,
  discover: number,
  ftcode: string,
  ftend: string,
  onesignal_hash: string,
  realtrading_access: number,
  top_badge: number,
  is_term_agreed: number,
  password: number,
};

export default { schema, propTypes };
